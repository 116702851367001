/* eslint-disable  */

export default {
  "About us": "Über uns",
  "Account": "Konto",
  "Add new address": "Neue Adresse hinzufügen",
  "Add to compare": "Hinzufügen zum vergleichen",
  "Add to Wishlist": "Zur Wunschliste hinzufügen",
  "Additional Information": "Zusätzliche Information",
  "All Orders": "Alle Bestellungen",
  "Allow order notifications": "Bestellbenachrichtigungen zulassen",
  "Apply": "Einlösen",
  "Applied Coupon": "Promo-Code",
  "Attention!": "Attention!",
  "Back": "Zurück",
  "Back to homepage": "Zurück zur Homepage",
  "Billing": "Rechnung",
  "Back to overview": "Zurück zur Übersicht",
  "Back to product": "Zurück zum Produkt",
  "Billing address": "Rechnungsadresse",
  "Brand": "Marke",
  "Calculate framesize": "Rahmengröße berechnen",
  "Calculate individual framesize": "Meine Rahmengröße berechnen",
  "Cancel": "Abbrechen",
  "Cart": "Warenkorb",
  "Categories": "Kategorien",
  "Change": "Ändern",
  "Change password your account": "Wenn du das Passwort für den Zugriff auf dein Konto ändern möchtest, gib die folgenden Informationen ein:",
  "Change to grid view": "Zur Rasteransicht wechseln",
  "Change to list view": "Zur Listenansicht wechseln",
  "Clear all": "Alles löschen",
  "Color": "Farbe",
  "Commercial information": "und erklären sich damit einverstanden, personalisierte Handelsinformationen vom Markennamen per E-Mail zu erhalten",
  "Contact details updated": "Halten deine Adressen und Kontaktdaten auf dem neuesten Stand.",
  "Contact details": "Kontaktdaten",
  "Contact us": "Kontaktiere uns",
  "Continue to billing": "Weiter zur Rechnung",
  "Continue to payment": "Weiter zur Bezahlung",
  "Continue to shipping": "Weiter zur Lieferung",
  "Create an account": "Benutzerkonto anlegen",
  "Customer Reviews": "Kundenbewertungen",
  "Customer service": "Kundendienst",
  "Date": "Datum",
  "Default Billing Address": "Standard-Rechnungsadresse",
  "Default Shipping Address": "Standard-Lieferadresse",
  "Delete": "Löschen",
  "Departments": "Abteilungen",
  "Description": "Beschreibung",
  "Details and status orders": "Überprüfen Sie die Details und den Status Ihrer Bestellungen im Online-Shop. Sie können Ihre Bestellung auch stornieren oder eine Rücksendung anfordern. ",
  "Discount": "Rabatt",
  "Done": "Fertig",
  "Download": "Herunterladen",
  "Download all": "Alle herunterladen",
  "Edit": "Bearbeiten",
  "Email": "Email",
  "Email address": "E-Mail Adresse",
  "Empty": "Sieht so aus, als hätten Sie der Tasche noch keine Artikel hinzugefügt. Beginnen Sie mit dem Einkaufen, um es auszufüllen.",
  "Empty bag": "Leere Tasche",
  "Engine": "Motor",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Promocode eingeben",
  "Feedback": "Ihr Feedback ist uns wichtig. Lassen Sie uns wissen, was wir verbessern können.",
  "Feel free to edit": "Du kannst deine Kontaktdaten jederzeit bearbeiten und aktualisieren.",
  "Female": "Weiblich",
  "Filter heading": "Filtern",
  "Filters": "Filter",
  "Find out more": "Finde mehr heraus",
  "First Name": "Vorname",
  "Forgot Password": "Bitte gib hier deine E-Mail-Adresse ein und wir senden dir einen Link, mit dem du dein Passwort zurücksetzen kannst.",
  "Forgot Password Modal Email": "E-Mail Adresse",
  "forgotPasswordConfirmation": "Wenn ein Konto mit der angegebenen E-Mail Adresse verknüpft ist, erhaltest du eine E-Mail mit einem Link zum Zurücksetzen deines Passworts. Bitte überprüfe sicherheitshalber auch den Spam-Folder.",
  "Forgotten password?": "Passwort vergessen?",
  "Forward": "Weiter",
  "Gender": "Geschlecht",
  "Go back": "Geh zurück",
  "Go back shopping": "Weiter einkaufen",
  "Go back to shop": "Zurück zum Einkaufen",
  "Continue shopping": "Weiter einkaufen",
  "Go to checkout": "Zur Kassa",
  "Go to cart": "Zum Warenkorb",
  "Guarantee": "Garantie",
  "Height": "Körpergröße (cm)",
  "Help": "Hilfe",
  "Help & FAQs": "Hilfe & FAQs",
  "hide": "ausblenden",
  "Home": "Startseite",
  "How do I measure my steplength": "Wie messe ich meine Schritthöhe?",
  "I agree to": "Ich stimme zu",
  "I have the": "Ich habe die",
  "and that": "und das",
  "read and accept these.": "gelesen und akzeptiere diese.",
  "I confirm subscription": "Ich bestätige das Abonnement",
  "I want to create an account": "Ich möchte ein Konto erstellen",
  "Info after order": "Sie können sich mit E-Mail und definiertem Passwort in Ihrem Konto anmelden vorhin. Überprüfen Sie in Ihrem Konto Ihre Profildaten Transaktionsverlauf, Abonnement für Newsletter bearbeiten.",
  "Instruction1": "Um mich kümmern",
  "Instruction2": "Nur hier für die Pflegehinweise?",
  "Instruction3": "Ja, das haben wir uns gedacht",
  "It was not possible to request a new password, please check the entered email address.": "Es war nicht möglich, ein neues Passwort anzufordern, bitte überprüfen Sie die eingegebene E-Mail-Adresse.",
  "Item": "Artikel",
  "Items": "Gegenstände",
  "Kidswear": "Kinderkleidung",
  "Last Name": "Nachname",
  "Learn more": "Mehr erfahren",
  "Let’s start now – we’ll help you": "Fangen wir jetzt an - wir helfen Ihnen.",
  "Log into your account": "In dein Konto einloggen",
  "Login": "Anmelden",
  "login in to your account": "Zurück zum Login",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Dein Warenkorb ist leer. Leg los und befülle den Warenkorb.",
  "Looks like you haven’t added any items to the Wishlist.": "Sieht so aus, als hätten Sie keine Artikel zur Wunschliste hinzugefügt.",
  "Make an order": "Bestellung aufgeben",
  "Order for a fee": "Kostenpflichtig bestellen",
  "Male": "Männlich",
  "Manage addresses": "Du kannst hier eine/verschiedene Adressen für Rechnung und Lieferung hinterlegen",
  "Manage billing addresses": "Alle gewünschten Rechnungsadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Rechnungsadresse nicht bei jeder Bestellung manuell eingeben.",
  "Manage shipping addresses": "Alle gewünschten Versandadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Versandadresse nicht bei jeder Bestellung manuell eingeben.",
  "Match it with": "Kombiniere es mit",
  "Men fashion": "Herrenmode",
  "Menu": "Menü",
  "My billing and shipping address are the same": "Meine Rechnungs- und Lieferadresse sind identisch",
  "My Cart": "Mein Warenkorb",
  "New Products": "Neue Produkte",
  "No account": "Bist du neu hier?",
  "No bike is like the other": "Kein Radmodell gleicht dem anderen — darum haben wir jedes einzeln vermessen. ",
  "No declaration": "Keine Angabe",
  "or": "oder",
  "or fill the details below": "oder füllen Sie die Details unten",
  "Order ID": "Bestellung #",
  "Order information": "Bestellinformationen",
  "Order No.": "Deine Bestellnummer lautet: ",
  "Order summary": "Bestellübersicht",
  "Other products you might like": "Das könnte dir auch gefallen",
  "View All": "Alle anzeigen",
  "Out of stock": "Ausverkauft",
  "Password": "Passwort",
  "Confirm Password": "Passwort bestätigen",
  "Password Changed": "Du kannst dich nun wie gewohnt über den Profil-Button in der Navigationsleiste anmelden.",
  "Accepts Terms and Conditions 1": "Ich habe die ",
  "Accepts Terms and Conditions 2": " gelesen und bin damit einverstanden.",
  "Terms And Conditions": "AGBs",
  "Pay for order": "Für Bestellung bezahlen",
  "Payment": "Bezahlung",
  "Payment & Delivery": "Zahlung & Lieferung",
  "Payment Method": "Zahlungsmethode",
  "Payment Methods": "Zahlungsarten",
  "Personal details": "Daten",
  "Please type your current password to change your email address.": "Bitte geben Sie Ihr aktuelles Passwort ein, um Ihre E-Mail-Adresse zu ändern.",
  "Price": "Preis",
  "Privacy": "Datenschutz",
  "Privacy Policy": "Datenschutzrichtlinie",
  "Product": "Produkt",
  "Product description": "Das Karissa V-Neck Tee hat eine halb taillierte Form schmeichelhaft für jede Figur. Sie können mit ins Fitnessstudio gehen Vertrauen, während es Kurven umarmt und häufiges \"Problem\" verbirgt Bereiche. Finden Sie atemberaubende Cocktailkleider für Frauen und feiern Sie Kleider.",
  "Product suggestions": "Produktvorschläge",
  "Products": "Anzahl Artikel",
  "Products found": "Produkte gefunden",
  "Purchase terms": "Kaufbedingungen",
  "Quality in the details": "Qualität im Detail",
  "Quantity": "Anzahl",
  "Read all reviews": "Alle Bewertungen lesen",
  "Read and understand": "Ich habe das gelesen und verstanden",
  "Read reviews": "Bewertungen lesen",
  "Register": "Benutzerkonto anlegen",
  "Register to the Newsletter": "Anmelden",
  "Register today": "Benutzerkonto anlegen",
  "Remove": "Löschen",
  "Remove Address": "Adresse entfernen",
  "Remove from Wishlist": "Von Wunschliste entfernen",
  "Repeat Password": "Passwort wiederholen",
  'Reset': 'Zurücksetzen',
  'Reset Mobile': 'Filter zurücksetzen',
  "Reset Password": "Hast du dein Passwort vergessen?",
  "Reset Password success": "Passwort erfolgreich zurückgesetzt!",
  "Reset Password Button": "Absenden",
  "Reset Password Error": "Beim zurücksetzen des Passworts ist ein Fehler aufgetreten.",
  "Results": "Ergebnisse",
  "Review my order": "Meine Bestellung überprüfen",
  "Same as shipping address": "Wie Versandadresse",
  "Save changes": "Änderungen speichern",
  "Save for later": "Für später speichern",
  "Save Password": "Passwort speichern",
  "Search": "Suche",
  "Search for items": "Nach Artikeln suchen",
  "Search results": "Suchergebnisse",
  "Sections that interest you": "Abschnitte, die Sie interessieren",
  "See all": "Alle anzeigen",
  "See all results": "Alle Ergebnisse anzeigen",
  "See more": "Mehr sehen",
  "Select payment method": "Zahlungsmethode auswählen",
  "Select shipping method": "Zur Versandart",
  "Send my feedback": "Senden Sie mein Feedback",
  "Set up newsletter": "Richten Sie Ihren Newsletter ein und wir senden Ihnen wöchentlich Informationen zu neuen Produkten und Trends aus den von Ihnen ausgewählten Bereichen",
  "Share your look": "Teile deinen Look",
  "Delivery and Shipping": "Lieferung & Versand",
  "Shipping address": "Lieferadresse",
  "Shipping address with clickn collect": "Adresse",
  "Shipping clickn collect hint": "*Aus Gründen der Nachhaltigkeit versenden wir Zubehör nur gemeinsam mit einem Neurad im Karton - so sparen wir Verpackungsmaterial und Zustellkilometer.",
  "Shipping details": "Versanddetails",
  "Shipping method": "Versandart",
  "Show": "Anzeigen",
  "Show more": "Zeig mehr",
  "Show on page": "Auf Seite anzeigen",
  "Sign in": "Anmelden",
  "Size guide": "Größentabelle",
  "Sign Up for Newsletter": "Anmeldung für Newsletter",
  "Sort by": "Sortieren nach",
  "Sort: Default": "Standard",
  "Sort: Name A-Z": "Bezeichnung A-Z",
  "Sort: Name Z-A": "Bezeichnung Z-A",
  "Sort: Brand Name A-Z": "Hersteller A-Z",
  "Sort: Brand Name Z-A": "Hersteller Z-A",
  "Sort: Price from high to low": "Höchster Preis zuerst",
  "Sort: Price from low to high": "Niedrigster Preis zuerst",
  "Sort: Bestseller": "Bestseller",
  "Sort: Relevance from high to low": "Höchste Relevanz zuerst",
  "Sort: Relevance from low to high": "Niedrigster Relevanz zuerst",
  "Start shopping": "Einkaufen starten",
  "Status": "Status",
  "Steplength Info Headline": "So ermittelst du deine Schritthöhe:",
  "Steplength Info Text 1": "Als Schritthöhe wird der Abstand zwischen Fußsohle und Schritt (Genitalbereich) im Stehen verstanden.",
  "Steplength Info Text 2": "Stelle dich aufrecht hin und klemme ein Buch in den Schritt, der Druck sollte dem Satteldruck entsprechen.",
  "Steplength Info Text 3": "Miss nun senkrecht mit einem Maßband den Abstand vom Boden bis zur Oberkante des Buches. Der gemessene Wert in cm entspricht deiner Schritthöhe.",
  "StepLength": "Schritthöhe (cm)",
  "Subscribe": "Abonnieren",
  "Subscribe to newsletter": "Anmeldung zum Newsletter",
  "subscribeToNewsletterModalContent": "Wenn Sie sich für den Newsletter angemeldet haben, erhalten Sie spezielle Angebote und Nachrichten von VSF per E-Mail. Wir werden Ihre E-Mail zu keinem Zeitpunkt an Dritte verkaufen oder weitergeben. Bitte beachten Sie unsere {0}.",
  "Subtotal": "Zwischensumme (inkl. MwSt.)",
  "Subtotal price": "Zwischensumme Preis",
  "Successful placed order": "In Kürze erhälst du eine E-Mail mit einer detaillierten Bestellbestätigung sowie einen Link zur Überprüfung des Fortschritts deiner Bestellung.",
  "Terms and conditions": "Allgemeine Geschäftsbedingungen",
  "Thank you": "Vielen Dank!",
  "Thank You Inbox": "Wenn die Nachricht nicht in deinem Posteingang ankommt, versuche es mit einer anderen E-Mail Adresse, mit der du dich möglicherweise registriert hast.",
  "Total": "Gesamtsumme (inkl. MwSt.)",
  "This is the way we calculate your framesize": "Nur so können wir dir deine optimale Rahmengröße für jedes Radmodell berechnen.",
  "Order Total": "Auftragssumme",
  "Total items": "Gesamtanzahl",
  "Total price": "Gesamtsumme (inkl. MwSt.)",
  "Number of items": "Anzahl Artikel",
  "Update password": "Passwort aktualisieren",
  "Update personal data": "Daten aktualisieren",
  "Use your personal data": "Bei Markennamen legen wir großen Wert auf Datenschutzfragen und verpflichten uns, die persönlichen Daten unserer Benutzer zu schützen. Erfahren Sie mehr darüber, wie wir Ihre persönlichen Daten pflegen und verwenden",
  "User Account": "Daten",
  "View": "Ansicht",
  "View details": "Bestellung ansehen",
  "Vue Storefront Next": "Vue Storefront Next",
  "We haven’t found any results for given phrase": "Wir haben keine Ergebnisse für die angegebene Phrase gefunden",
  "Weight with kg": "Gewicht (kg)",
  "Who we are": "Wer wir sind",
  "Wishlist": "Wunschliste",
  "Women fashion": "Damenmode",
  "You added {product} to your shopping cart.": "Sie haben {product} zu Ihrem Warenkorb hinzugefügt.",
  "You are not authorized, please log in": "Sie sind nicht autorisiert, bitte melden Sie sich an",
  "You can unsubscribe at any time": "Sie können sich jederzeit abmelden",
  "You currently have no orders": "Sie haben derzeit keine Bestellungen",
  "The email field must be a valid email": "Bitten gib eine gültige E-Mail Adresse ein",
  "You haven’t searched for items yet": "Sie haben noch nicht nach Artikeln gesucht.",
  "Your bag is empty": "Ihre Tasche ist leer",
  "Your current email address is": "Ihre aktuelle E-Mail-Adresse lautet",
  "Your email": "E-Mail Adresse",
  "Your individual framesize": "Deine individuelle Rahmengröße",
  "Your personal framesize": "Deine persönliche Rahmengröße",
  "A customer with the same email address already exists in an associated website.": "Ein Kunde mit derselben E-Mail-Adresse existiert bereits auf einer verknüpften Website.",
  "Invalid login or password.": "Eingabe stimmt nicht mit aktuellem Passwort überein",
  "SUMMER COLLECTION {year}": "SOMMERKOLLEKTION {year}",
  "Colorful summer dresses are already in store": "Bunte Sommerkleider sind bereits im Store",
  "Dresses": "Kleider",
  "Cocktail & Party": "Cocktailparty",
  "Linen Dresses": "Leinenkleider",
  "T-Shirts": "T-Shirts",
  "The Office Life": "Das Büroleben",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Finden Sie atemberaubende Cocktailkleider für Damen und Cocktailkleider für Party-D…c von all Ihren Lieblingsmarken.",
  "Shop now": "Jetzt einkaufen",
  "Summer Sandals": "Sommersandalen",
  "Eco Sandals": "Öko-Sandalen",
  "Subscribe to Newsletters": "Newsletter abonnieren",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Informieren Sie sich über bevorstehende Verkäufe und Veranstaltungen. Erhalten Sie Geschenke und Sonderangebote",
  "Fashion to take away": "Mode zum Mitnehmen",
  "Download our application to your mobile": "Laden Sie unsere Anwendung auf Ihr Handy herunter",
  "Share Your Look": "Teilen Sie Ihren Look",
  "My Account": "Mein Konto",
  "My profile": "Mein Profil",
  "Personal Details": "Persönliche Daten",
  "Addresses details": "Adressendetails",
  "My newsletter": "Mein Newsletter",
  "Log out": "Ausloggen",
  "My reviews": "Meine Bewertungen",
  "Order history": "Bestellverlauf",
  "Order details": "Bestelldetails",
  "My wishlist": "Meine Wunschliste",
  "Password change": "Passwort ändern",
  "Personal data": "Persönliche Daten",
  "Your e-mail": "Deine E-Mail",
  "Current Password": "Aktuelles Passwort",
  "You are not authorized, please log in.": "Sie sind nicht autorisiert, bitte melden Sie sich an.",
  "Go To Product": "Zum Produkt",
  "Returns": "Rücklauf",
  "My orders": "Meine Bestellungen",
  "Add the address": "Adresse hinzufügen",
  "Set as default shipping": "Als Standard-Lieferadresse verwenden",
  "Set as default billing": "Als Standard-Rechnungsadresse verwenden",
  "House/Apartment number": "Haus-/Wohnungsnummer",
  "Street Name": "Straßenname",
  "City": "Stadt",
  "State/Province": "Staat/Provinz",
  "Zip-code": "PLZ",
  "Country": "Land",
  "Phone number": "Telefonnummer",
  "This field is a required field": "Dieses Feld ist ein Pflichtfeld",
  "Please select a country first": "Bitte wählen Sie zuerst ein Land aus",
  "This field is required": "Dieses Feld ist ein Pflichtfeld",
  "New Password": "Neues Passwort",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Für dieses Land sind keine Versandarten verfügbar. Es tut uns leid, bitte versuchen Sie es mit einem anderen Land oder später.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Beim Versuch, Versandarten abzurufen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit anderen Versanddetails oder später.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Beim Versuch, diese Versandart auszuwählen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit einer anderen Versandart.",
  "We can't find products matching the selection.": "Wir können keine Produkte finden, die der Auswahl entsprechen.",
  "Page not found": "Upps… diese Seite haben wir leider nicht gefunden",
  "Page not found - text": "Es könnte sein, dass du eine falsche oder veraltete URL aufgerufen hast.",
  "Back to Home page": "Zurück zur Startseite",
  "To our bikes": "Zu unseren Rädern",
  "An error occurred": "Ein Fehler ist aufgetreten",
  "AllProductsFromCategory": "Alle {categoryName}",
  "Show less": "Zeige weniger",
  "Yes": "Ja",
  "No": "Nein",
  "Apply filters": "Filter anwenden",
  "The coupon code isn't valid. Verify the code and try again.": "Bitte geben Sie einen gültigen Promocode ein.",
  "From": "Aus",
  "To": "Zu",
  "To - bis": "bis",
  "Your customization": "Ihre Anpassung",
  "Show all products": "Alle Produkte anzeigen",
  "Select previously saved address": "Zuvor gespeicherte Adresse auswählen",
  "Enter different address": "Oder gib hier eine neue Lieferadresse ein",
  "You must confirm your account. Please check your email for the confirmation link.": "Sie müssen Ihr Konto bestätigen. Bitte überprüfen Sie Ihre E-Mail auf den Bestätigungslink.",
  "Please check your email to complete account verification.": "Bitte überprüfe deine E-Mails, um die Verifizerung des Accounts abzuschließen.",
  "Change Store": "Laden Wechseln",
  "Choose Currency": "Währung wählen",
  "Add a review": "Bewertung hinzufügen",
  "Add to cart": "In den Warenkorb",
  "Title": "Titel",
  "Name": "Name",
  "Review": "Rezension",
  "Add review": "Bewertung hinzufügen",
  "Are you sure you would like to remove this item from the shopping cart?": "Sind Sie sicher, dass Sie diesen Artikel aus dem Warenkorb entfernen möchten?",
  "Your cart is empty": "Dein Warenkorb ist leer",
  "Are you sure?": "Bist du dir sicher?",
  "{0} has been successfully removed from your cart": "{0} wurde erfolgreich aus dem Warenkorb entfernt",
  "Amount": "Betrag",
  "Thank you for your order!": "Vielen Dank für deinen Einkauf!",
  "Thank you for your message!": "Vielen Dank für deine Nachricht!",
  "Message sent succesfully": "Sie wurde erfolgreich an unser Serviceteam weitergeleitet. Wir bemühen uns deine Anfrage schnellst möglichst zu bearbeiten.",
  "Your Purchase": "Ihr Einkauf",
  "Primary contacts for any questions": "Primäre Ansprechpartner für alle Fragen",
  "Your Account": "Ihr Konto",
  "What can we improve": "Was können wir verbessern",
  "Payment date": "Datum",
  "The user password was changed successfully updated!": "Passwort erfolgreich aktualisiert",
  "The user account data was successfully updated!": "Daten erfolgreich aktualisiert!",
  "You submitted your review for moderation.": "Sie haben Ihre Bewertung zur Moderation eingereicht.",
  "Starting at": "Beginnt um",
  "Your e-mail address": "Deine E-Mail Adresse",
  "NewsletterText": "Mit unserem Newsletter bleibst du im Sattel & sicherst dir 15% Rabatt auf deinen nächsten Einkauf bei Bernhard Kohl.",
  'Bernhard Kohl – Bike & Fitness': 'Bernhard Kohl – Fahrrad & Fitness',
  'Contact': 'Kontakt',
  'Opening Hours': 'Öffnungszeiten',
  'Clock': 'Uhr',
  'Shipping Methods': 'Versandarten',
  'Quality Seal': 'Qualitätssiegel',
  'Imprint': 'Impressum',
  'AGB': 'AGB',
  'Data Protection': 'Datenschutz',
  "Right of Withdrawal": "Widerrufsrecht",
  'Cookies Policy': 'Cookie Richtlinien',
  "Sale": "Im Angebot",
  "Sale detail": "({discountedPercentage}% Rabatt)",
  "Available soon": "Bald erhältlich",
  "New": "Neu",
  "Welcome to BikeFinder": "Willkommen beim BikeFinder - deinem Fahrrad-Experten!",
  "Welcome to BikFinder Description": "Entdecke mit uns die Welt des Radfahrens, von der Auswahl deines perfekten Fahrrads bis zum optimalen Fahrerlebnis.",
  "Lets go": "Los geht's!",
  "I am looking for a bike": "Ich suche ein Rad ...",
  "With engine": "mit Motor",
  "Without engine": "ohne Motor",
  "I would like a": "Ich hätte gerne ein ...",
  "Continue": "Weiter",
  "Choose one option": "Wähle eine Option aus.",
  "Choose more options": "Wähle eine oder mehrere Optionen aus.",
  "What requirements": "Welche Anforderung stellst du an dein Rennrad?",
  "How much do you want to spend": "Wie viel möchtest du ausgeben?",
  "Show bikes": "Fahrräder anzeigen",
  "Gravel translation": "Welche Art der Übersetzung suchst du?",
  "Crossbike unterground": "Auf welchem Untergrund hast du vor zu fahren?",
  "Frame shape": "Hast du eine bevorzugte Rahmenform?",
  "Kidsbike body size": "Für welche Körpergröße suchst du?",
  "Wheel inches": "Wieviel Zoll sollen die Laufräder haben?",
  "Hardtail or fully": "Hardtail oder Fully?",
  "Where are you travelling": "Wo bist du unterwegs?",
  "Frame which material": "Aus welchem Material soll der Rahmen sein?",
  "Weight or range": "Was ist dir wichtiger - Gewicht oder Reichweite?",
  "Suspension fork": "Soll dein Rad eine Federgabel haben?",
  "Arrange purchase advice": "Kaufberatung vereinbaren",
  "Stock frame sizes: ": "Lagernde Rahmengrößen: ",
  "Calculate your perfect frame size": "Berechne deine perfekte Rahmengröße",
  "incl. VAT.,": "inkl. MwSt, ",
  "excl. Shipping costs": "zzgl. Versandkosten",
  "Collection / delivery within 5 to 7 days": "Abholung / Lieferung binnen 5 bis 7 Tagen",
  "Clickn collect": "Abholung per click’n’collect oder",
  "Delivery with bike": "Lieferung mit deinem Neurad binnen 5 bis 7 Tage",
  "Our tip: Buy it as a business bike": "Unser Tipp:  Als Dienstrad erwerben",
  "Exhibited in the shop": "Im Shop ausgestellt",
  "Only 1 stock left": "Nur noch 1 stück lagernd",
  "The perfect height for you is": "Die perfekte Größe für dich ist",
  "Please choose a frame size": "Bitte wähle eine Rahmengröße",
  "Desired frame size currently not in stock?": "Gewünschte Rahmengröße aktuell nicht lagernd? ",
  "Contact us for further steps": "Kontaktiere uns für weitere Schritte",
  "Inquiry about": "Anfrage zu",
  "Please let us know your desired frame size:": "Bitte teile uns deine gewünschte Rahmengröße mit:",
  "Model Year": "Modelljahr",
  "Key Data": "Eckdaten",
  "Operation Area": "Einsatzgebiet",
  "Geometry": "Geometrie",
  "Weight": "Gewicht",
  "Frame Shape": "Rahmenform",
  "Material": "Material",
  "Frame": "Rahmen",
  "Fork": "Gabel",
  "Landing Gear": "Fahrwerk",
  "Travel": "Federweg",
  "Damper": "Dämpfer",
  "Suspension Manufacturer": "Fahrwerk Hersteller",
  "Drive": "Antrieb",
  "Drive Type": "Antriebsart",
  "Transmission": "Übersetzung",
  "Circuit Manufacturer": "Schaltungshersteller",
  "Groupset Type": "Schaltgruppenart",
  "Groupset": "Schaltgruppe",
  "Rear Derailleur": "Schaltwerk",
  "Front Derailleur": "Umwerfer",
  "Shifter": "Schalthebel",
  "Crankset": "Kurbelsatz",
  "Cassete": "Kassette",
  "Brakes": "Bremsen",
  "Brake System Type": "Bremsanlagenart",
  "Brake System": "Bremsanlage",
  "Brakes Manufacturer": "Bremsenhersteller",
  "Wheels": "Laufräder",
  "Wheel Size": "Laufraddimension",
  "Wheelset material": "Laufradsatz Material",
  "Tires": "Reifen",
  "Cockpit": "Cockpit",
  "Integrated Cockpit": "Integriertes Cockpit",
  "Saddle": "Sattel",
  "Seatpost": "Sattelstütze",
  "E-Bike System": "E-Bike System",
  "Engine Manufacturer": "Motor Hersteller",
  "Drive Unit": "Drive Unit",
  "Drive Unit Individual": "Drive Unit",
  "Battery Pack": "Akku",
  "Handlebars": "Lenker",
  "Range Extender Optional": "Range Extender Optional",
  "Display": "Display",
  "Display Individual": "Display",
  "Remote": "Remote",
  "Remote Individual": "Remote",
  "Charger": "Ladegerät",
  "Charger Individual": "Ladegerät",
  "Stem": "Vorbau",
  "General": "Allgemein",
  "Body size children's bikes": "Körpergröße Kinderräder",
  "Luggage Rack System": "Gepäckträger-System",
  "Geometry & Dimensions": "Geometrie & Maße",
  "Product out of stock": "Produkt nicht vorrätig",
  "Test drive this bike?": "Dieses Bike probefahren?",
  "Test drive?": "Bike probefahren?",
  "Arrange your expert consultation": "Vereinbare deine Expertenberatung",
  "With": "Mit",
  "financing": "Finanzierung",
  "to your dream bike": "zum Traumbike",
  "Financing options from": "ab € {minimumRate},- / Monat",
  "Financing options to": "zum Finanzierungsrechner",
  "Financing calculator": "Finanzierungsrechner",
  "Financing modal title": "Der Rechner dient nur der Orientierung für die Finanzierung deines neuen Fahrrads oder E-Bikes. Komm einfach bei uns im Geschäft vorbei.",
  "Financing modal infos": "Infos und Details",
  "Subject of your request": "Betreff deiner Anfrage",
  "Regarding": "Betreff",
  "Comment": "Kommentar",
  "Do you want us to call you?": "Willst du, dass wir dich anrufen?",
  "Submit": "Kontakt aufnehmen",
  "General Request": "Allgemeine Anfrage",
  "Buying a bike": "Radkauf",
  "Service": "Servicepoint",
  "Service bike leasing": "Dienstrad-Leasing",
  "The field should have at maximum 40 characters": "Das Feld sollte maximal 40 Zeichen enthalten",
  "The field should have at maximum 50 characters": "Das Feld sollte maximal 50 Zeichen enthalten",
  "The field should have minimum 10 characters": "Das Feld sollte mindestens 10 Zeichen enthalten",
  "Your shopping basket is empty. Go ahead and fill the shopping cart.": "Dein Warenkorb ist leer. Leg los und befülle den Warenkorb.",
  "Only wheels in my size": "Nur Räder in meiner Größe",
  "Change Wheels Filters": "Ändern",
  "Reset to default": "Zurücksetzen",
  "Close Filters": "Filter schließen",
  "Operation Data": "Einsatzgebiet",
  "Gift card": "Gutschein",
  "Gift card info 1": "Sofortige Zusendung des Gutscheins an die hinterlegte E-Mail Adresse",
  "Gift card info 2": "Einlösbar in unserem Store in Wien und online",
  "Gift card info 3": "Sichere Bezahlung mit Kreditkarte oder PayPal",
  "Addresses": "Adresse(n)",
  "Add address": "Adresse hinzufügen",
  "Edit address": "Adresse aktualisieren",
  "Company": "Firma",
  "Street name and house number": "Straße + Hausnr.",
  "Please enter your customer number": "Bitte gib hier deine Kundennummer ein, um das genaue Lieferdatum von deinem Rad zu erfahren. Die Kundennummer findest du auf der Rechnung.",
  "Get date": "Datum abfragen",
  "The current delivery date for your bike is": "Das aktuelle Lieferdatum für dein Fahrrad ist <b>KW {week} {year}</b>.",
  "The bike was assembled by the manufacturer and shipped to us.": "Das Rad wurde vom Hersteller fertig montiert und an uns verschickt.",
  "We expect your bike in": "Wir erwarten dein Fahrrad circa in <b>KW {week} {year}</b>.",
  "Your delivery date": "Dein Lieferdatum",
  "Currently, no order has been entered under this customer number.": "Aktuell ist noch keine Bestellung unter dieser Kundennummer erfasst.",
  "Get delivery date": "Lieferdatum abfragen",
  "Please tell us your customer number": "Bitte sag uns deine Kundennummer",
  "Customer number not correct. Please check your input": "Kundennummer nicht korrekt. Bitte überprüfe deine Eingabe",
  "Gender required": "Bitte sag uns dein Geschlecht",
  "Height required": "Bitte sag uns deine Körpergröße",
  "Weight required": "Bitte sag uns dein Gewicht",
  "Belly Button Height required": "Bitte sag uns dein Bauchnabelhöhe",
  "Step length required": "Bitte sag uns deine Schritthöhe",
  "Step length image alt text": "Schritthöhe Animation",
  "Create an account on the store": "Neues Benutzerkonto anlegen",
  "Login on the store": "Anmelden",
  "Shipping": "Versandkosten",
  "belly_button_height": "Bauchnabelhöhe",
  "height": "Körpergröße",
  "seat_position": "Sitzposition",
  "weight": "Gewicht",
  "Expert advice": "Expertenberatung buchen",
  "Free seat position adjustment worth € 99.—": "Kostenlose Sitzpositionseinstellung im Wert von € 99,—",
  "We adjust your bike perfectly to your individual body dimensions.": "Wir stellen dein Rad perfekt auf deine individuellen Körpermaße ein.",
  "Adjust sitting position": "Sitzposition einstellen",
  "Continue without adjusting the seat position": "Ohne Sitzpositionseinstellung fortfahren",
  "Your perfect sitting position": "Deine perfekte Sitzposition",
  "No two bike models are alike": "Kein Radmodell gleicht dem anderen — darum haben wir jedes einzeln vermessen. Nur so können wir dir deine individuelle Rahmengröße für jedes Radmodell berechnen.",
  "Calculate sitting position": "Sitzposition berechnen",
  "cm": "cm",
  "kg": "kg",
  "How do I measure my belly button height?": "Wie messe ich meine Bauchnabelhöhe?",
  "As": "Als",
  "Belly button height is the distance": " wird der Abstand zwischen Fußsohle und Schritt (Genitalbereich) im Stehen verstanden.",
  "This is how you determine yours": "So ermittelst du deine",
  "Stand upright and hold a book on your crotch": "Stelle dich aufrecht hin und klemme ein Buch in den Schritt, der Druck sollte dem Satteldruck entsprechen.",
  "Now measure vertically with a tape measure the distance from the floor to the top edge of the book.": "Miss nun senkrecht mit einem Maßband den Abstand vom Boden bis zur Oberkante des Buches. Der gemessene Wert in cm entspricht deiner Schritthöhe.",
  "Continue without sitting position": "Ohne Sitzposition fortfahren",
  "Continue with sitting position": "Mit Sitzposition fortfahren",
  "Order confirmation": ", um eine Bestellbestätigung zu drucken.",
  "Click here": "Klicke hier",
  "Pars pro toto": "Pars pro toto",
  "Here you will find a selection of our most popular items.": "Hier findest du eine Auswahl unserer beliebtesten Artikel. Du kannst sie per Click & Collect in unserem Webshop erwerben und innerhalb der Öffnungszeiten bei uns im Shop abholen.",
  "For the full selection of more than 3,000 different items, visit us in our store on Triester Straße.": "Für die volle Auswahl von mehr als 3000 verschiedenen Artikeln besuch’ uns in unserem Geschäft auf der Triester Straße.",
  "If you also order your bike in our webshop, we will of course ship everything together.": "Wenn du auch dein Rad in unserem Webshop bestellst, verschicken wir natürlich alles gemeinsam.",
  "E-mail": "E-Mail",
  "Yes, I agree to the Terms of Service": "Ja, ich habe die {link} gelesen und akzeptiert",
  "Send": "Absenden",
  "validation": {
    "max": "Das Feld sollte maximal {length} Zeichen enthalten",
    "min": "Das Feld sollte mindestens {length} Zeichen enthalten",
    "required": "Dieses Feld ist ein Pflichtfeld",
    "email": "Bitte gib eine gültige E-Mail Adresse ein",
    "confirmed": "Die Passwörter stimmen nicht überein",
    "password": "Das Passwort muss aus 8 Zeichen, 1 Sonderzeichen, 1 Großbuchstaben und 1 Nummer bestehen",
  },
  "Thank you for your interest": "Vielen Dank für dein Interesse.",
  "We will do our best to process your request immediately and get back to you.": "Wir bemühen uns deine Anfrage umgehend zu bearbeiten und melden uns bei dir.",
  "Bikeleasing": "Bikeleasing",
  "Bikeleasing code": "Code {length}-stellig (z.B. {example})",
  "Leasemybike": "Leasemybike",
  "Jobrad": "Jobrad",
  "Registered e-mail address": "Registrierte E-Mail Adresse",
  "Lease a Bike": "Lease a Bike",
  "Company bike order code": "Bestellcode {length}-stellig (z.B. {example})",
  "Firmenradl": "Firmenradl",
  "Firmenradl code": "Code (z.B. {example})",
  "Ablo": "Ablo",
  "Ablo code": "Code {length}-stellig (z.B. JMHZE)",
  "Insert exact bike name or link to bike from store": "Genaue Fahrradbezeichnung oder Link zum Fahrrad aus Shop einfügen",
  "komfortabel": "Komfortabel",
  "professionell": "Professionell",
  "sportlich": "Sportlich",
  "Frametool total bikes": "Insgesamt haben wir <b>{amount} passende Räder</b> für dich.",
  "Frametool category bikes": "<b>{amount} davon sind {categoryName}</b>.",
  "Frametool show all": "Alle passenden Räder anzeigen",
  "Frametool show fitting": "Passende {categoryName} anzeigen",
  "Frametool no bikes found": "Wir haben leider keine Räder in deiner Größe.",
  "Frametool no bikes found button": "Alle Räder anzeigen",
  "Frametool individual bike not found": "Wir haben das <b>{manufacturerName} {bikeName}</b> leider nicht in deiner Größe.",
  "Frametool individual bike": "Deine Rahmengröße für <b>{manufacturerName} {bikeName}</b> lautet:",
  "Frametool individual bike size hint": "Deine Rahmengröße kann abhängig von Radmarke und Typ variieren.",
  "Frametool show individual bike": "Raddetails ansehen",
  "Frametool individual bike details not found": "Dieses Rad haben wir leider nicht in deiner Rahmengröße.",
  "Framesize calculation error": "Rahmengröße berechen",
  "Framesize calculation error text": "Beim Berechnen der Rahmengröße ist ein Fehler aufgetreten.",
  "Cookies on our website": "Cookies auf unserer Website",
  "We can only ensure that our site functions correctly with cookies.": "Nur mit Cookies können wir die korrekte Funktionsweise unserer Seite sicherstellen.",
  "We use cookies to improve the user experience": "Wir verwenden Cookies, um das Benutzererlebnis zu verbessern, die Anmeldung zu erleichtern sowie einen sicheren Login zu erlauben, Statistiken zu sammeln um die Seitenfunktionalität zu verbessern, sowie speziell auf deine Interessen abgestimmte Inhalte auszuliefern.",
  "By clicking on “Agree and Continue”": "Mit einem Klick auf „Zustimmen und Fortfahren“ akzeptierst du diese Cookies und wirst direkt auf die Seite weitergeleitet - welche Arten von Cookies wir im Detail verwenden ",
  "you can find out here.": "erfährst du hier.",
  "Agree and continue": "Zustimmen und fortfahren",
  "Only allow necessary cookies": "Nur notwendige Cookies zulassen",
  "Result": "Ergebnis",
  "Search results for: ": "Suchergebnisse für: ",
  "The largest bicycle shop in Austria": "Das größte Radgeschäft Österreichs – Mit kostenloser Sitzpositionseinstellung. Wir bieten die besten Räder von BMC, Mondraker, Scott, Haibike, Cube und Bianchi.",
  "Bikefinder interruption card headline": "Unschlüssig? Oder einfach neugierig?",
  "Bikefinder interruption card text": "Lass dich inspirieren und entdecke dein neues Fahrrad, vielleicht ganz wo anders als erwartet.",
  "Bikefinder interruption card button text": "Zu unserem Bike Finder",
  "Expert advice interruption card headline": "Ohne Wartezeit, zu deinem Wunschtermin",
  "Expert advice interruption card text first line": "Reden, fachsimpeln, diskutieren, fasziniert sein:",
  "Expert advice interruption card text second line": "90 Minuten lang gehört unsere volle Aufmerksamkeit ganz dir.",
  "Expert advice interruption card button text": "Kaufberatung vereinbaren",
  "Invalid Email": "Ungültige E-Mail Adresse",
  "The field should have at least 8 characters": "Passwort muss mindestens 8 Zeichen haben",
  "Our bicycles are delivered": "Unsere Fahrräder werden ohne Pedale ausgeliefert. Alle Pedale findest du",
  "here": "hier",
  "Remaining balance on Gift Card": "Restguthaben",
  "Applied Gift Card": "Angewendeter Gutschein",
  "Enter Gift Card code": "Gutscheincode eingeben",
  "The {0} gift card was already used.": "Die Geschenkkarte {0} wurde bereits verwendet.",
  "The {0} gift card is not active.": "Die Geschenkkarte {0} ist nicht aktiv.",
  "Please enter a valid promo code.": "Bitte geben Sie einen gültigen Promocode ein.",
  "Data Privacy Statement": "Datenschutzerklärung",
  "Account Confirmation Error": "Account-Verifizierung fehlgeschlagen",
  "Account Confirmation Success": "Account Verifizierung erfolgreich abgeschlossen",
  "One moment please ....": "Einen Moment Geduld bitte ....",
  "Stock size: ": "Lagernde Größen: ",
  "Please choose a size": "Bitte wähle eine Größe",
  "Theres more to see!": "Es gibt noch mehr zu sehen!",
  "Although we have shown all of our bikes online, we have only shown part of the rest of our range.": "Wir haben zwar alle unsere Räder online abgebildet, aber nur einen Teil unseres restlichen Sortiments.",
  "You can find the full selection of more than 3,000 different items in our shop on Triester Straße.": "Die volle Auswahl von mehr als 3000 verschiedenen Artikeln findest du in unserem Geschäft auf der Triester Straße.",
  "Interruption Clickn collect": "Nur CLICK’N’COLLECT - ausgenommen im Zuge eines Radkaufs.",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Die Anmeldung war nicht erfolgreich oder das Konto wurde vorübergehend deaktiviert. Bitte versuche es später erneut.",
  "The password must consist of 8 characters, 1 special character, 1 capital letter and 1 number": "Das Passwort muss aus mindestens 8 Zeichen, 1 Sonderzeichen, 1 Großbuchstaben und 1 Zahl bestehen",
  "Summary": "Zusammenfassung",
  "You have chosen the following options": "Folgende Optionen hast du gewählt",
  "Oops! Something went wrong": "Hoppla! Etwas ist schief gelaufen",
  "An error has occurred. Please try again or contact us directly via email.": "Es ist ein Fehler aufgetreten. Bitte versuche es erneut oder kontaktiere uns direkt via E-Mail.",
  "By clicking on “Accept all”": "Mit einem Klick auf „Alle akzeptieren“ akzeptierst du diese Cookies und wirst direkt auf die Seite weitergeleitet - welche Arten von Cookies wir im Detail verwenden ",
  "Continue as a guest": "Als Gast fortfahren",
  "Information about the bike": "Infos Zum Rad",
  "Technical data": "Technische Daten",
  "Bernhard's conclusion": "Bernhards Fazit",
  "Size/Step-length label": "Körpergröße / Schrittlänge",
  "Framesize label": "Rahmengröße",
  "Size or framesize required": "Körpergröße / Schrittlänge oder Rahmengröße müssen angegeben werden",
  "Company bike label": "Dienstrad-Anbieter",
  "Code label": "Code",
  "Bike label": "Fahrrad",
  "Reset sale filter": "Filter für 'Im Angebot' zurücksetzen",
  "New products filter label": "Neu",
  "Manufacturer Info": "Herstellerinfo & Sicherheitshinweise",
  "System Weight": "Systemgewicht",
  "Bike Classification": "Rad Klassifikation (DIN17406)",
  "Manufacturer Security Document": "Sicherheitshinweis",
  "Security Document Link": "Sicherheitshinweis ansehen",
  "Manufacturer Name": "Hersteller",
  "Manufacturer Address": "Anschrift",
  "Manufacturer Country": "Land",
  "Manufacturer Email": "E-Mail",
  "Manufacturer Phone Number": "Telefonnummer",
  "Manufacturer Info Empty": "Herstellerinfo",
  "Manufacturer Info Empty Text": "Herstellerinfo folgt bald",
  "Manufacturer EAN": "Hersteller EAN",
};
